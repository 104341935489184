<template>
    <div>
        <p class="text">
            {{ $t(text) }}
        </p>
        <div class="screenshot">
            <img class="step-image" :src="setImage" :alt="$t(text)">
        </div>
    </div>
</template>
<script>
export default {
    props: {
        step: Number
    },
    computed: {
        setImage() {
            return require(`./assets/step${this.step}.png`)
        }
    },
    data() {
        return {
            text: `faq.firestick.step${this.step}`
        }
    }
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
.step-image {
    width: 80%;
}
</style>
